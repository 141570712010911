html {
  width: 100%;                    /* 100% width of parent (root) element */
  height: 100vh;                  /* 100% height of viewport */
  background: #FCFBF9;  /* 10% opacity black (very light gray) */
  font-size: 1.0em;               /* root font size */
}

body {
  margin: 0;                      /* no margin at edge of content */
  height: 100%;                   /* 100% height of parent (html) element */
}